// Glyphicons font path
$icon-font-path:        "../fonts/";


$grid-columns:15;

// Grid settings
$main-sm-columns:       15;
$sidebar-sm-columns:    5;

// Colors
$brand-primary:         #404040;

$grid-gutter-width: 2px;