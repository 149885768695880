

html, body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 100%;
    margin: 0;
    color: $gray-base;
}
.home .main{
  padding:0;
}

.home h1{
    margin:0;
}
.home-brand {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

}


.home .container,.home .content.row, .home .main{
    height: 100%;
}


.vcenter{
    width: 100%;
    position: absolute;
    left:0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.home .owl-carousel .owl-lazy:before {
   content: "";
      float: left;
      padding-bottom: 62.5%;
}
 
.home .owl-carousel .owl-lazy:after {
clear: both;
 content: "";    
 display: table;
}
.home .owl-carousel .owl-item {
    height:100vh;
}

.home .owl-carousel .owl-lazy {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
 height:100vh;
}








.panel{opacity:1; margin: 2px;    padding: 15px 15px 30px 15px;background: #ffffff; border:1px solid #ddd;}
.description.not-visible,.panel.not-visible {
    opacity:0;
      -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.lazy{
    opacity:1;
}

.square-box{
    margin-bottom:$grid-gutter-width;
}


.entry-summary a>div:before {
  content: "";
  float: left;
  padding-bottom: 66%;
}

.entry-summary a>div:after {
  clear: both;
  content: "";    
  display: table;
}
.entry-summary{
        margin-bottom: $grid-gutter-width;
        div.lazy{
              background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  background-size:cover;
  background-color:$gray-lighter;
        }
}







.btn{
    padding: 6px 12px 3px;
}
.page-template-template-custom img{
    max-width: 100%;
}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
a, a:active, a:focus
{
    outline:0px !important;
    -webkit-appearance:none;
}

* {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

#tabs{
    font-size: 12px;
}

.nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nav-tabs-dropdown:before {
  content: "\e114";
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

.navbar {
    border: none;
    background-color: #fff;
    .navbar-nav > li > a {
        color: #B1B1B1;

    }
    .navbar-brand{
        text-transform: uppercase;
        color:#B1B1B1;
        span{
            color:#404040;
        }
    }
    .navbar-nav > li > a:hover,
    .navbar-nav > li > a:focus,
    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus {
        color: #404040;
        background-color: transparent;
    }
}
.columns{
    width: 90%;
    margin: 0 5%;
    .owl-carousel{
        margin-bottom: 30px;
        .owl-stage-outer .owl-stage .owl-item img.normal {
        max-width: 90%;
        margin: 0 5%;
        } 
    }
}
.full.owl-carousel {
    .owl-stage-outer {
        height: 100%;
        .owl-stage {
            height: 100%;
            .owl-item {
                height: 100%;
                .easyzoom{
                    width: 90%;
                    height: 100%;
                    margin: 0 5%;
                }
                img.normal {
                    max-width: 100%;
                    max-height: 100%;
                    width:auto;
                    margin: auto;
                    overflow: auto;
                    position: absolute;
                    z-index: 0;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    -webkit-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.modal{
    a.modal-title, a.captionlauncher{
        display: block;
        cursor: pointer;
    }
    .modal-dialog {
        min-width: 100%;
        margin: 0 auto;
        min-height: 100%;
    }
    .modal-content {
        border: none;
        border-radius: 0;
        min-height: 100%;
        width: 100%;
        position:absolute;
    }
    .modal-header{
        border:none;
        h3{
            margin-top:4px;
            margin-left: 5%;
        }
        p{
           margin-left: 5%; 
        }
      .close {
            margin-right: 5%;
        }
    }
    .modal-footer{
        border:none;
        .bckpost{
            margin:0 5% 0 0;
                border: none;
}
        }

    .modal-body {
        padding:0 15px;
        .entry-content{
            position: relative;
            width:100%;
        }
        iframe{
            padding:0 5%;
        }
    }
}




.caption-text{
    color: #404040;
    padding: 0 15px;
    text-align: center;
    h2{
            margin-top: 0;
    margin-bottom: 10px;
    font-size: inherit;
    }
} 
.caption:before {
    content:" ";
    padding-top: 100%;
    width:0;
    display:inline-block;
    vertical-align:middle;
}
.full-width-box .caption:before{
    padding:0;
    height: 100%;
} 
.caption {
  position: absolute;
  width: 100%; 
  height: 100%;
  box-sizing:border-box;
  padding:5px;
  top: 0; 
  left: 0;
  background-color: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  span {
      display: inline-block;
      vertical-align:middle;
      max-width: 90%
  }
}
.caption:hover, .caption:focus {
    opacity: 0.9;
}

.single .entry-content{
    position: relative;
}


.owl-nav>div{
    position: absolute;
    top: 0;
    height: 100%;
    width: 5%;
    text-align: center;
    span{
        top: 50%;
    }
}
.owl-prev{
    left: 0;
}
.owl-next{
    right: 0;
}

footer{
    min-height: 15px;
}


/**
 * EasyZoom core styles
 */
.easyzoom {
    position: relative;

    /* 'Shrink-wrap' the element */
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

.easyzoom img {
    vertical-align: bottom;
}

.easyzoom.is-loading img {
    cursor: progress;
}

.easyzoom.is-ready img {
    cursor: crosshair;
    width:auto;
}

.easyzoom.is-error  img {
    cursor: not-allowed;
}

.easyzoom-notice {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 150;
    width: 10em;
    margin: -1em 0 0 -5em;
    line-height: 2em;
    text-align: center;
    background: #FFF;
    box-shadow: 0 0 10px #888;
}

.easyzoom-flyout {
    position:absolute;
    z-index: 100;
    overflow: hidden;
    background: #FFF;
}

/**
 * EasyZoom layout variations
 */
.easyzoom--overlay .easyzoom-flyout {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    margin-left: 20px;
}


.page .h1, .page h1 {
    font-size: 14px;
}


@media (min-width:  $screen-xs-max) {
    .navbar {
    .container-fluid{
    margin-top:50px;
    }
}
    body{
        padding-top:160px;
    }
   body.home{
    padding-top: 0;
   }

      #tabs{
    display: block!important;
        margin-bottom:30px;
  }
  .home .container-fluid{
    width: 100%;
  }
  .container-fluid{
    width: 85%;
  }
}

@media (max-width:  $screen-xs-max) {
    body{
        padding-top:70px;
    }
       body.home{
    padding-top: 0;
   }
     .home .container-fluid{
    width: 100%;
  }
  .container-fluid{
    width: 95%;
  }
     .nav-tabs-dropdown {
        display: block;
        margin-top: 20px;
        margin-bottom:30px;
    }
    #tabs {
        display: none;
    }

}